import React, {useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import BorderActive from "../../workFields/BorderActive";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useNavigate} from "react-router";
import { IsReplacementComponent } from "./lib/IsReplacementComponent";

const BlockSwiper = ({firstLoad, component, refImage, mainId, load, phone, isActivePage}) => {

	const [mouseOver, setMouseOver] = useState(false)

	let navigate = useNavigate();

	const {
		width, height, isHide, ref,
		idActive, selectThisComponent,
		resize
	} = useBorderActive(component, [mainId, component.id], load, null)

	const setAnimate = (flag) => {
		if (component?.hover?.hoverText || component?.colorDress.visible) {
			setMouseOver(flag)
		}
	}
	const setActiveOrLinkTo = (event) => {
		if (!isHide) {
			resize()
			selectThisComponent(event)
		} else {
			if (component?.link?.isActive) {
				if (component?.link?.out) {
					window.open(component.link.path)
				} else {
					let arr = component.link.path.split('.com')
					navigate(arr[1].slice(7))
				}
			}
		}
	}

	return (
		<div className='p-relative'>
			{!isHide && idActive.length === 2 && idActive[1] === component?.id && !isActivePage &&
				<BorderActive
					width={width}
					height={height}
					blockSwiper={true}
					btnDown={true}
					id={[mainId, component.id]}
				/>
			}
			<div
				ref={ref}
				className={styled.itemContainer}
				onClick={setActiveOrLinkTo}
				onMouseOver={() => setAnimate(true)}
				onMouseOut={() => setAnimate(false)}
			>
				<div className={styled.imgSize}>
					<img
						ref={refImage}
						onLoad={firstLoad}
						src={component.image.url} alt=""
						style={{width: phone ? '100%' : 'none'}}
					/>
				</div>
				<div
					className={`${styled.frontSpace} ${component?.hover?.hoverBackground ? styled.hoverBackground : ''}`}
					style={{
						backgroundImage: `url(${component.image.url})`,
						justifyContent: component?.styles?.justifyContent
					}}
				>
					{component?.colorDress.visible &&
					<div
						className={ mouseOver
							? `${styled.cardColor} ${styled.cardColorsHover}`
							: `${styled.cardColor}`
						}
					>
						{component?.colorDress.colors.map((item, key) => {
								return  <div
									key={key}
									className={ item === '#ffffff'
										? `${styled.color} ${styled.light}`
										: `${styled.color}`
									}
									style={{background: item}}
								/>
							}
						)}
					</div>
					}
					{component?.components?.map((item, key) =>
						<React.Fragment key={key}>
							<IsReplacementComponent
								item={item}
								mainId={mainId}
								component={component}
								mouseOver={mouseOver}
								isActivePage={isActivePage}
							/>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default BlockSwiper;