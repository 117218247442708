import {editorAPI} from "../../api/api";
import {setPaginationData, setTemplateList} from "../reducers/templatesReducer";

export const setTemplates = (page) => {
  return async (dispatch) => {
    const res = await editorAPI.getList(page)
    dispatch(setTemplateList(res.data));
    dispatch(setPaginationData(res.pagination))
  }
}

