import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'

const SettingAnimation = ({hover, changeParams, isReplacement}) => {

	const setAnimate = (params) => {
		changeParams('hover', params, isReplacement)
	}

	//{[item[0]]: val}

	return (
		<div>
			{hover
				?
				<div className={styled.animate}>
					<h3>Анимация</h3>
					<div className={styled.blocks}>
						{Object.entries(hover).map((item, key) =>
							<React.Fragment key={key}>
								{item[0] === 'hoverBackground' ?
									<div
										className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
										onClick={() => setAnimate({[item[0]]: !item[1]})}
									>
										<div className={styled.text}>Фон</div>
										<div className={styled.photoBG}/>
									</div>
									///АНИМАЦИЯ ТЕКСТА
								/*	: item[0] === 'hoverText' ?
										<div
											className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
											onClick={() => setAnimate({[item[0]]: !item[1]})}
										>
											<div className={styled.text}>Текст</div>
											<div className={styled.photoTX}>
												<div className={styled.TX}>text</div>
											</div>
										</div>*/
										:
										<></>
								}
							</React.Fragment>
						)}
					</div>
				</div>
				:
				<></>
			}
		</div>
	);
};

export default SettingAnimation;