import React, {useEffect, useState} from 'react';
import CreateTemplateButton from "../components/TemplatesPage/CreateTemplateButton";
import {useDispatch, useSelector} from "react-redux";
import {getActivePage, getTemplates} from "../redux/selectors/templatesSelectors";
import TemplatesList from "../components/TemplatesPage/TemplatesList";
import {setTemplates} from "../redux/actions/templatesAction";
import {message} from "antd";
import {getMessages} from "../redux/selectors/errorsSelectors";
import {setMessages} from "../redux/reducers/errorsReducer";
import {setActiveComponent} from "../redux/reducers/editorReducer";

const Templates = () => {

	const templates = useSelector(state => getTemplates(state));
	const messages = useSelector(state => getMessages(state));
	const activePage = useSelector(state => getActivePage(state));

	const dispatch = useDispatch()

	const setMessagesRedux = (val) => {
		dispatch(setMessages(val))
	}

	const handleCloseError = () => {
		setMessagesRedux([])
	}

	useEffect(() => {
		dispatch(setActiveComponent([]))
	}, [])

	const showMessage = () => {
		message.info({
			content: messages[0],
			onClose: handleCloseError
		});
	};
	useEffect(() => {
		if (messages.length) {
			showMessage()
		}
	}, [messages])
	useEffect(() => {
		dispatch(setTemplates(activePage))
	}, [activePage])


	return (
		<div >
			<CreateTemplateButton setMessages={setMessagesRedux}/>
			<TemplatesList templates={templates} setMessages={setMessagesRedux}/>

		</div>
	);
};

export default Templates;