import React from 'react';
import styled from '../../../../../styles/editorPage/workField.module.scss'

function CountDownTimeDisplay(props) {
  const {
    value,
    type,
    isDot,
    styles
  } = props

  return (
    <div
      className={styled.display}
      style={{
        fontSize: styles.fontSizeTime,
        fontFamily: styles.fontFamily,
        color: styles.color
      }}
    >
      <div className={styled.display_value}>
        {value}
        {isDot && <div className={styled.display_dot}>.</div>}
      </div>
      <span
        className={styled.display_type}
        style={{
          fontSize: styles.fontSizeType,
        }}
      >
        {type}
      </span>
    </div>
  );
}

export default CountDownTimeDisplay;