import uuid from "react-uuid";
import vertical from '../assets/imagesBgBlock/vertical.jpg'
import horisontal from '../assets/imagesBgBlock/horisontal.jpg'
import fr1 from '../assets/imagesBgBlock/1fr.jpg'
import fr2 from '../assets/imagesBgBlock/2fr.jpg'
import fr3 from '../assets/imagesBgBlock/3fr.jpg'
import fr7 from '../assets/imagesBgBlock/7fr.jpg'
import certificates_new from '../assets/certificates_new.jpg'

const langList = (languages) => {
  let result = {}
  for (let key of languages) {
    if (key === 'ru') {
      result = {...result, [key]: 'КАКОЙ-ТО ТЕКСТ'}
    } else if (key === 'en') {
      result = {...result, [key]: 'SOME TEXT'}
    } else if (key === 'de') {
      result = {...result, [key]: 'ETWAS TEXT'}
    } else if (key === 'ar') {
      result = {...result, [key]: 'بعض النصوص'}
    } else {
      result = {...result, [key]: 'КАКОЙ-ТО ТЕКСТ'}
    }
  }
  return result
}
const langListSliced = (languages, id) => {
  console.log(id)
  let result = {}
  for (let key of languages) {
    result = {...result, [key]: [sliced(key, id)]}
  }
  return result
}
const getText = (lang) => {
  if (lang === 'ru') {
    return 'БЛОК ТЕКСТ'
  } else if (lang === 'en') {
    return 'BLOCK TEXT'
  } else if (lang === 'de') {
    return 'BLOCK TEXT'
  } else if (lang === 'ar') {
    return 'حظر النص'
  } else {
    return 'КАКОЙ-ТО ТЕКСТ'
  }
}
///////////////////////////////////////
///////////////////////////////////////
///////Элементы четвертого уровня//////
export const sliced = (lang, id) => {
  return {
    name: 'sliced',
    id: uuid(),
    textSliced: getText(lang),
    styles: {
      parentId: id,
      selfStyle: false,
      color: '#ffffff',
      fontFamily: 'Futura PT',
      fontWeight: 300,
      fontSize: '20px',
    }
  }
}
///////////////////////////////////////
///////////////////////////////////////
///////Элементы третьего уровня////////
export const text = (languages) => {
  return {
    name: 'text',
    id: uuid(),
    text: {...langList(languages)},
    styles: {
      alignSelf: 'center',
      margin: ['0px', '0px', '0px', '0px'],
      color: '#ffffff',
      fontFamily: 'Futura PT',
      fontWeight: 300,
      fontSize: '20px',
    },
  }
}
export const textSliced = (languages) => {
  const id = uuid()
  return {
    name: 'text_sliced',
    id: id,
    styles: {
      alignSelf: 'center',
      margin: ['0px', '0px', '0px', '0px'],
      color: '#ffffff',
      fontFamily: 'Futura PT',
      fontWeight: 300,
      fontSize: '20px',
    },
    ...langListSliced(languages, id)
  }
}
export const blockTextBg = (languages) => {
  return {
    name: 'block_text_bg',
    id: uuid(),
    text: {...langList(languages)},
    styles: {
      alignSelf: 'center',
      margin: ['0px', '0px', '0px', '0px'],
      padding: ['0px', '25px', '0px', '25px'],
      color: '#ffffff',
      background: '#000000',
      fontFamily: 'Futura PT',
      fontWeight: 300,
      fontSize: '20px',
    },
  }
}

export const button_outline = (languages) => {
  return {
    name: 'button_outline',
    id: uuid(),
    text: {...langList(languages)},
    link: {
      isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    styles: {
      alignSelf: 'center',
      margin: ['0px', '0px', '0px', '0px'],
      borderColor: '#ffffff',
      color: '#ffffff',
      fontFamily: 'Futura PT',
      fontWeight: 300,
      fontSize: '20px',
    },
  }
}

export const countdownTimer = () => {
  let date = new Date();
  date.setDate(date.getDate() + 10)
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  ];
  const dateStr = `${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]} ${date.getFullYear()}`

  return {
    name: 'countdown_timer',
    id: uuid(),
    datePiker: {
      date: dateStr,
      time: '00:00:00',
    },
    styles: {
      alignSelf: 'center',
      margin: ['0px', '0px', '0px', '0px'],
      color: '#f64747',
      fontFamily: 'Futura PT',
      fontSizeTime: '50px',
      fontSizeType: '15px',
    }
  }
}

///////////////////////////////////////
///////////////////////////////////////
///////Элементы второго уровня////////
export const blockOne = (ratio) => {
  return {
    id: uuid(),
    name: 'block',
    image: {
      url: vertical,
      path: '',
      ...ratio
    },
    hover: {
      hoverBackground: false,
      hoverText: false,
    },
    link: {
      isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    styles: {
      justifyContent: 'center',
    },
    components: []
  }
}
export const blockFive = (ratio) => {
  return {
    id: uuid(),
    name: 'block',
    image: {
      url: horisontal,
      path: '',
      ...ratio
    },
    hover: {
      hoverBackground: false,
      hoverText: false,
    },
    link: {
      isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    styles: {
      justifyContent: 'center',
    },
    components: []
  }
}
export const blockSix = (languages, ratio) => {
  return {
    id: uuid(),
    name: 'block',
    image: {
      url: certificates_new,
      path: '',
      ...ratio
    },
    hover: {
      hoverBackground: false,
      hoverText: false,
    },
    link: {
      isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    styles: {
      justifyContent: 'end',
    },
    components: [
      {
        name: 'button_outline',
        id: uuid(),
        text: {...langList(languages)},
        link: {
          isActive: true,
          out: false,
          path: 'https://lichi.com/ru/ru/new',
          name: 'test'
        },
        styles: {
          alignSelf: 'center',
          margin: ['0px', '0px', '56px', '0px'],
          borderColor: '#000000',
          color: '#000000',
          fontFamily: 'Futura PT',
          fontWeight: 300,
          fontSize: '20px',
        },
      },
    ]
  }
}

export const blockCustomSize = (fr) => {
  let ratio = ''
  let url = ''
  if (fr === '1fr') {
    url = fr1
    ratio = {
      ratioDesk: '2500x3500',
      ratioPhone: '2500x3500'
    }
  }
  if (fr === '2fr' || fr === '7fr') {
    url = fr2
    ratio = {
      ratioDesk: '5000x3500',
      ratioPhone: '5000x3500'
    }
  }
  if (fr === '3fr') {
    url = fr3
    ratio = {
      ratioDesk: '2143x3500',
      ratioPhone: '2143x3500'
    }
  }
  return {
    id: uuid(),
    name: 'block',
    image: {
      path: '',
      url,
      ...ratio
    },
    hover: {
      hoverBackground: false,
      hoverText: false,
    },
    link: {
      isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    styles: {
      justifyContent: 'center',
    },
    components: [],
  }
}
///////////////////////////////////////
///////////////////////////////////////
///////Элементы первого уровня////////
export const swiperDesktop = (languages, ratio) => {
  return {
    id: uuid(),
    name: 'swiper_desktop',
    image: {
      url: vertical,
      path: '',
      ...ratio
    },
    styles: {
      justifyContent: 'end',
    },
    hover: {
      hoverBackground: false,
      hoverText: false,
    },
    link: {
      		isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    colorDress: {
      visible: false,
      colors: []
    },
    components: [
      {
        name: 'text',
        id: uuid(),
        text: {...langList(languages)},
        styles: {
          alignSelf: 'start',
          margin: ['0px', '0px', '50px', '30px'],
          color: '#ffffff',
          fontFamily: 'Futura PT',
          fontWeight: 300,
          fontSize: '20px',
        },
      },
      {
        name: 'text',
        id: uuid(),
        text: {...langList(languages)},
        styles: {
          alignSelf: 'start',
          margin: ['0px', '0px', '50px', '30px'],
          color: '#ffffff',
          fontFamily: 'Futura PT',
          fontWeight: 300,
          fontSize: '20px',
        },
      },
    ]
  }
}
export const swiperPhone = (ratio) => {
  return {
    id: uuid(),
    name: 'swiper_phone',
    image: {
      url: horisontal,
      path: '',
      ...ratio
    },
    styles: {
      justifyContent: 'center',
    },
    hover: {
      hoverBackground: false,
      hoverText: false,
    },
    link: {
      	isActive: true,
      out: false,
      path: 'https://lichi.com/ru/ru/new',
      name: 'test'
    },
    colorDress: {
      visible: false,
      colors: []
    },
    components: []
  }
}
///////////////////////////////////////
///////////////////////////////////////
/////////////Конструкторы//////////////

export const getInitialStyles = (name, count, languages) => {
  // count может быть массивом фракций

  let component = {}
  let blocks = []
  let user = 'all'
  let countries = {
    checked: ['all'],
    all: true
  }
  const gridTemplateColumns = () => {
    let result = []
    for (let i = 0; i < count; i++) {
      result = [...result, '1fr']
    }
    return result
  }

  if (name === 'block-custom-size') {
    for (let fr of count) {
      let initial = blockCustomSize(fr)
      blocks = [...blocks, initial]
    }
  }

  if (name === 'block-image') {
    const ratio = {
      ratioDesk: '3024x3780',
      ratioPhone: '3024x3780'
    }
    for (let i = 0; i < count; i++) {
      let initial = blockOne(ratio)
      blocks = [...blocks, initial]
    }
  }

  if (name === 'block-image-full-screen') {
    const ratio = {
      ratioDesk: '1920х1080',
      ratioPhone: '1080х1920'
    }
    for (let i = 0; i < count; i++) {
      let initial = blockFive(ratio)
      blocks = [...blocks, initial]
    }
  }
  if (name === 'block-image-w-half-h') {
    const ratio = {
      ratioDesk: '1920х540',
      ratioPhone: '1920х540'
    }
    for (let i = 0; i < count; i++) {
      let initial = blockSix(languages, ratio)
      blocks = [...blocks, initial]
    }
    countries = {
      checked: ['kz', 'ru'],
      all: false
    }
    user = 'retail'
  }

  ////////Переопределение имен////////
  if (name === 'block-custom-size') {
    name = 'block-image'
  }
  ////////////////////////////////////

  component = {
    name: name,
    id: uuid(),
    components: blocks,
    styles: {
      gridGap: '2px',
      gridTemplateColumns: Array.isArray(count) ? count : gridTemplateColumns()
    },
    view: {
      user: user,
      countries: countries
    }
  }
  return component
}

export const getInitialStylesSW = (name, count, languages) => {
  let component = {}
  let blocks = []

  if (name === 'block-slider') {
    const ratio = {
      ratioDesk: '3024x3780',
      ratioPhone: '3024x3780'
    }
    for (let i = 0; i < count; i++) {
      let initial = swiperDesktop(languages, ratio)
      blocks = [...blocks, initial]
    }
    component = {
      name: 'block-slider',
      id: uuid(),
      components: blocks,
      view: {
        wholesale: true,
        countries: {
          checked: ['all'],
          all: true
        }
      }
    }

    return component
  }
  if (name === 'block-slider-full') {
    const ratio = {
      ratioDesk: '1920х540',
      ratioPhone: '1920х540'
    }
    for (let i = 0; i < count; i++) {
      let initial = swiperPhone(ratio)
      blocks = [...blocks, initial]
    }
    component = {
      name: 'block-slider-full',
      id: uuid(),
      components: blocks,
      view: {
        user: 'all',
        countries: {
          checked: ['all'],
          all: true
        }
      }
    }

    return component
  }

  if (name === 'block-video') {
    const ratio = {
      ratioDesk: '1920х1080',
      ratioPhone: '1080х1920'
    }
    component = {
      name: 'block-video',
      id: uuid(),
      isPlay: false,
      video: 'https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25',
      image: {
        url: 'https://i.vimeocdn.com/video/1473820692-063b7eb8b4dd16415f043d07301a8babb370b30c4d1f95385363e5c4a4d19ae8-d_1920',
        path: '',
        ...ratio
      },
      link: {
        isActive: true,
        out: false,
        path: 'https://lichi.com/ru/ru/new',
        name: 'test'
      },
      view: {
        user: 'all',
        countries: {
          checked: ['all'],
          all: true
        }
      },
      components: [
        {
          id: uuid(),
          name: 'block',
          hover: {
            hoverText: false,
          },
          styles: {
            justifyContent: 'center',
          },
          components: []
        }
      ]
    }

    return component
  }
}

