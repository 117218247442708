import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setDeleteActiveComponent, setStylesActiveComponent } from "../../../../../redux/reducers/editorReducer";
import { choiceComponent } from "./choiceComponentFN";

export const IsReplacementComponent = (props) => {

  const {
    item,
    mainId,
    component,
    mouseOver,
    isActivePage,
  } = props

  const replacementElement = useMemo(() => {
    return (
      component?.componentsReplacement?.filter(itemRepl => itemRepl.id === item.id)
        ? component.componentsReplacement?.filter(itemRepl => itemRepl.id === item.id)[0]
        : null
    )
  }, [component?.componentsReplacement, item.id])

  const dispatch = useDispatch()

  //Запуск таймеров
  useEffect(() => {
    let timerReplacement
    let timerDelete
    if (item?.datePikerDelete) {
      const date = `${item.datePikerDelete.date} ${item.datePikerDelete.time}`
      const countdownDateTime = new Date(date).getTime();
      const currentTime = new Date().getTime()
      const remainingDayTime = countdownDateTime - currentTime;
      if (remainingDayTime > 0) {
        timerDelete = setTimeout(() => dispatch(setDeleteActiveComponent([mainId, component.id, item.id])), remainingDayTime);
      } else {
        dispatch(setDeleteActiveComponent([mainId, component.id, item.id]))
      }
    } else {
      clearTimeout(timerDelete);
    }
    if (replacementElement) {
      const replacementId = [mainId, component.id, replacementElement.id]
      const date = `${replacementElement?.datePikerReplacement.date} ${replacementElement?.datePikerReplacement.time}`
      const countdownDateTime = new Date(date).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      if (remainingDayTime > 0) {
        if (replacementElement.deadline) {
          dispatch(setStylesActiveComponent('deadline', false, true, replacementId))
        }
        timerReplacement = setTimeout(() => dispatch(setStylesActiveComponent('deadline', true, true, replacementId)), remainingDayTime);
      } else {
        if (!replacementElement.deadline) {
          dispatch(setStylesActiveComponent('deadline', true, true, replacementId))
        }
      }
    }
    return () => {
      clearTimeout(timerReplacement);
      clearTimeout(timerDelete);
    };
  }, [replacementElement, item])


  return (
    <React.Fragment>
      {
        replacementElement?.isPreview || replacementElement?.deadline
          ? choiceComponent(
            replacementElement.name, mainId, component.id,
            replacementElement, mouseOver, component?.hover?.hoverText,
            isActivePage, component?.componentsReplacement
          )
          : choiceComponent(
            item.name, mainId, component.id, item,
            mouseOver, component?.hover?.hoverText,
            isActivePage, component?.components
          )
      }
    </React.Fragment>
  )
}
