import React from 'react';
import {useSelector} from "react-redux";
import BorderActive from "../../workFields/BorderActive";
import {getLang} from "../../../../redux/selectors/editorSelectors";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useNavigate} from "react-router";
import styled from '../../../../styles/editorPage/workField.module.scss'

const ButtonOutline = ({mainId, blockId, component, isActivePage, collection}) => {

	const lang = useSelector(state => getLang(state));

	let navigate = useNavigate();

	const {
		width, height, isHide, ref, resize,
		idActive, selectThisComponent,
	} = useBorderActive(component, [mainId, blockId, component.id])


	const setActiveOrLinkTo = (event) => {
		if (!isHide) {
			resize()
			selectThisComponent(event)
		} else {
			if (component?.link?.isActive) {
				if (component?.link?.out) {
					window.open(component.link.path)
				} else {
					let arr = component.link.path.split('.com')
					navigate(arr[1].slice(7))
				}
			}
		}
	}
	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{
				alignSelf: component?.styles.alignSelf,
			}}
		>
			<div
				className='p-relative'
				style={{
					margin: component.styles.margin.join(' ')
				}}>
				{!isHide && idActive.length === 3 && component.id === idActive[2] && !isActivePage &&
				<BorderActive
					width={width}
					height={height}
					id={[mainId, blockId, component.id]}
					large={true}
					collection={collection}
					container={true}
				/>}
				<button
					ref={ref}
					className={styled.buttonOutline}
					style={{
						fontWeight: component.styles.fontWeight,
						fontSize: component.styles.fontSize,
						fontFamily: component.styles.fontFamily,
						borderColor: component.styles.borderColor,
						color: component.styles.color,
					}}
					onClick={setActiveOrLinkTo}
				>
					{component.text[lang]}
				</button>
			</div>
		</div>
	);
};

export default ButtonOutline;
