import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Input} from "antd";

const SettingTextSliced = ({textSliced, changeParams, name, isReplacement}) => {

  return (
    <div>
      {textSliced !== undefined
        ?
          <div className={styled.rightStylesMainMargin}>
            <Input
              value={textSliced}
              size={'small'}
              type="text"
              onChange={(e) => changeParams('textSliced', e.target.value, isReplacement)}
            />
          </div>
          :
          <></>
      }
    </div>
  );
};


export default SettingTextSliced;
