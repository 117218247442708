import React, { useEffect, useMemo, useState } from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import BorderActive from "../../workFields/BorderActive";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import { useNavigate } from "react-router";
import { IsReplacementComponent } from "./lib/IsReplacementComponent";

const Block = ({firstLoad, component, mainId, load, isActivePage, device, parentName, heightComponent}) => {

  const [mouseOver, setMouseOver] = useState(false)

  let navigate = useNavigate();

  const {
    width, height, isHide, ref,
    idActive, selectThisComponent,
    resize
  } = useBorderActive(component, [mainId, component.id], load)

  const paramsBg = useMemo(() => {
    if ((parentName === 'block-image-full-screen') || (parentName === 'block-image-w-half-h')) {
      return {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    }
    return {}
  }, [parentName])

  const setAnimate = (flag) => {
    if (component?.hover?.hoverText) {
      setMouseOver(flag)
    }
  }

  const setActiveOrLinkTo = (event) => {
    if (!isHide) {
      resize()
      selectThisComponent(event)
    } else {
      if (component?.link?.isActive) {
        if (component?.link?.out) {
          window.open(component.link.path)
        } else {
          let arr = component.link.path.split('.com')
          navigate(arr[1].slice(7))
        }
      }
    }
  }


  return (
    <div className={`p-relative  ${heightComponent}`}>
      {!isHide && idActive.length === 2 && idActive[1] === component?.id && !isActivePage &&
        <BorderActive
          width={width}
          height={height}
          btnDown={true}
          id={[mainId, component.id]}
        />
      }
      <div
        className={styled.itemContainer}
        ref={ref}
        onClick={setActiveOrLinkTo}
        onMouseOver={() => setAnimate(true)}
        onMouseOut={() => setAnimate(false)}
        style={{
          height: ((parentName === 'block-image-full-screen') || (parentName === 'block-image-w-half-h')) ? '100%' : 'auto'
        }}
      >
        {!(parentName === 'block-image-full-screen' && device !== 'desktop') &&
          <div className={styled.imgSize}>
            <img
              onLoad={firstLoad}
              src={component.image.url} alt="" />
          </div>
        }
        <div
          className={`${styled.frontSpace} ${component?.hover?.hoverBackground ? styled.hoverBackground : ''}`}
          style={{
            backgroundImage: `url(${component.image.url})`,
            justifyContent: component?.styles?.justifyContent,
            ...paramsBg
          }}
        >
          {component?.components?.map((item, key) =>
            <React.Fragment key={key}>
              <IsReplacementComponent
                item={item}
                mainId={mainId}
                component={component}
                mouseOver={mouseOver}
                isActivePage={isActivePage}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Block;