import React, {useEffect, useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import BorderActive from "../../workFields/BorderActive";
import DashPlayerPrew from "../../workFields/DashPlayerPrew";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useNavigate} from "react-router";

const VideoContainerPrew = ({mainId, component, collection, isActivePage}) => {

  const [refInView, inView] = useInView({threshold: 0.1})
  const [autoplay, setAutoplay] = useState(false)

  const playVideo = () => {
    setAutoplay(prev => !prev)
  }

  let navigate = useNavigate();

  const {
    width, height, isHide, resize,
    idActive, selectMainComponent, ref
  } = useBorderActive(component, [component?.id])

  const setActiveOrLinkTo = () => {
    playVideo()
    if (isHide) {
      if (component?.link?.isActive) {
        if (component?.link?.out) {
          window.open(component.link.path)
        } else {
          let arr = component.link.path.split('.com')
          navigate(arr[1].slice(7))
        }
      }
    }
  }
  useEffect(() => {
    if (isHide) {
      setAutoplay(true)
    }
  }, [isHide])

  const select = (e) => {
    resize()
    selectMainComponent(e)
  }

  return (
    <div className='p-relative w-100'>
      {!isHide && idActive.length === 1 && idActive[0] === component?.id && !isActivePage &&
        <BorderActive
          width={width}
          height={height}
          id={[mainId]}
          collection={collection}
          btnDown={true}
          container={true}
        />
      }
      <div
        ref={refInView}
        className={`${inView ? styled.mosaicCardShow : styled.mosaicCardHide}`}
      >
        <div
          onMouseDown={select}
          onContextMenu={(e) => e.preventDefault()}
          ref={ref}
          onClick={setActiveOrLinkTo}
        >
          <DashPlayerPrew src={component.video} poster={component?.image?.url} autoplay={autoplay}/>
        </div>
      </div>
    </div>
  );
};

export default VideoContainerPrew;
