import React, { useMemo, useState } from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { useSelector } from "react-redux";
import { getDevice } from "../../../../redux/selectors/editorSelectors";
import ModalFileManager from "../../../Modals/ModalFileManager";

const SettingImages = ({image, changeParams, name, isReplacement}) => {


  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const device = useSelector(state => getDevice(state));

  const inform = useMemo(() => {
    if (image?.ratioDesk) {
      return `Рекомендуемые параметры изображения, для соблюдения пропорций компонента - ${
        device === 'desktop' ? image.ratioDesk : image.ratioPhone
      }`
    }
    if (name === 'block-image' || name === 'block-slider') {
      return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 3024x3780'
    }
    if (name === 'block-video' || name === 'block-slider-full' || name === 'block-image-full-screen') {
      if (device === 'desktop') {
        return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1920х1080'
      } else {
        return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1080х1920'
      }
    }
    if (name === 'block-image-full-w-half-h' || name === 'block-slider-full') {
      return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1920х540'
    }
    return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1080х1920'
  }, [name, image])

  const title = useMemo(() => {
    if (name === 'block-video') {
      return 'Постер';
    }
    return 'Изображение';
  }, [name])

  const setNewImage = (val) => {
    changeParams('image', val, isReplacement)
    handleClose()
  }

  return (
    <div>
      {image
        ?
        <div>
          <h3>{title}</h3>
          <div className={`${styled.addImage}`}>
            <div
              className={styled.icons}
              onClick={handleOpen}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            >
              <div className={styled.plus}>+</div>
              <div className={styled.upload}>
                Upload
              </div>
            </div>
            <div className={styled.inform}>
              {inform}
            </div>
          </div>
          {/*				<div style={{margin: '0 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '300px', paddingRight: '5px'}}>
						<div style={{fontWeight: '500'}}>Активное фото: </div>
						<div style={{border: '1px dashed #d6d6d6', padding: '5px', background: '#fafafa', color: '#a8a8a8'}}>	<img src={image.url} style={{width: '60px', height: '60px'}} alt=""/> {image.path}</div>
					</div>*/}
        </div>
        :
        <></>
      }
      <ModalFileManager open={open} handleClose={handleClose} setNewImage={setNewImage} />
    </div>
  );
};

export default SettingImages;
