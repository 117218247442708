import {ADD_TEMPLATE_LIST, CHANGE_ACTIVE_PAGE, CHANGE_PAGINATION_DATA} from '../types'

let initialState = {
  templates: [],
  activePage: '1',
  paginationData: {
    total: 0,
    limit: 10
  }
};

const templatesReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADD_TEMPLATE_LIST:
      return {
        ...state, templates: action.templates
      }
    case CHANGE_ACTIVE_PAGE:
      return {
        ...state, activePage: action.activePage
      }
    case CHANGE_PAGINATION_DATA:
      return {
        ...state, paginationData: action.data
      }
    default:
      return state;
  }
}

export const setTemplateList = (templates) => ({type: ADD_TEMPLATE_LIST, templates});
export const setActivePage = (page) => ({type: CHANGE_ACTIVE_PAGE, page});
export const setPaginationData = (data) => ({type: CHANGE_PAGINATION_DATA, data});

export default templatesReducer;
