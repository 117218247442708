import Text from "../../Elements/Text";
import TextSliced from "../../Elements/TextSliced/TextSliced";
import TextBG from "../../Elements/TextBG";
import ButtonOutline from "../../Elements/ButtonOutline";
import MosaicCountDownTimer from "../../Elements/CountDownTimer/MosaicCountDownTimer";
import React from "react";

export const choiceComponent = (
  name, mainId, blockId, component,
  mouseOver, hover, isActivePage,
  collection
) => {
  if (name === 'text') {
    return (
      <Text
        mainId={mainId}
        blockId={blockId}
        component={component}
        mouseOver={mouseOver}
        hover={hover}
        isActivePage={isActivePage}
        collection={collection}
      />
    )
  }
  if (name === 'text_sliced') {
    return (
      <TextSliced
        mainId={mainId}
        blockId={blockId}
        component={component}
        mouseOver={mouseOver}
        hover={hover}
        isActivePage={isActivePage}
        collection={collection}

      />
    )
  }
  if (name === 'block_text_bg') {
    return (
      <TextBG
        mainId={mainId}
        blockId={blockId}
        component={component}
        mouseOver={mouseOver}
        hover={hover}
        isActivePage={isActivePage}
        collection={collection}
      />
    )
  }
  if (name === 'button_outline') {
    return (
      <ButtonOutline
        mainId={mainId}
        blockId={blockId}
        component={component}
        isActivePage={isActivePage}
        collection={collection}
      />
    )
  }
  if (name === 'countdown_timer') {
    return (
      <MosaicCountDownTimer
        mainId={mainId}
        blockId={blockId}
        component={component}
        isActivePage={isActivePage}
        collection={collection}
      />
    )
  }
}