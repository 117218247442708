import React, { useRef, useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Controller, Autoplay } from "swiper";
import BorderActive from "../../workFields/BorderActive";
import BlockSwiper from "../Blocks/BlockSwiper";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";

SwiperCore.use([Controller, Autoplay ]);

const SwiperContainerPhone = ({mainId, component, collection, isActivePage}) => {

	const [refInView, inView] = useInView({threshold: 0.1})

	const [firstSwiper, setFirstSwiper] = useState(null);

	const refImage = useRef()

	const {
		width, height, isHide,
		idActive, selectMainComponent,
		firstLoadSwiper, load, ref, resize
	} = useBorderActive(component, [component?.id])

	const select = (e) => {
		resize()
		selectMainComponent(e)
	}
	return (
		<div
			className='p-relative'
			ref={ref}
		>
			{!isHide && idActive.length === 1 && idActive[0] === component.id && !isActivePage &&
			<BorderActive
				width={width}
				height={height}
				id={[mainId]}
				collection={collection}
				btnDown={true}
				container={true}
			/>
			}
			<div
				onMouseDown={select}
				onContextMenu={(e) => e.preventDefault()}
				ref={refInView}
				className={`${styled.swiperContainer} ${inView ? styled.mosaicCardShow : styled.mosaicCardHide}`}
			>
				<div className="slider-width-progress">
					<Swiper
						spaceBetween={0}
						slidesPerView={1}
						loop
						watchSlidesProgress={true}
						autoplay={{
							delay: 6000,
							disableOnInteraction: false,
						}}
						speed={2000}
						nested={true}
						onSwiper={setFirstSwiper}
						controller={{control: firstSwiper}}
						className={'mySwiper'}
					>
						{ component?.components.map((item, id) =>
							<SwiperSlide key={id} className={!id ? 'firstSlide' : ''}>
								<BlockSwiper
									firstLoad={firstLoadSwiper}
									component={item}
									refImage={refImage}
									mainId={mainId}
									load={load}
									phone={true}
									isActivePage={isActivePage}
								/>
								<div className='own-progress'>
									<div className='bar-transition'/>
									<div className={!id ? 'bar-transition-first' : 'bar-transition'}/>
								</div>
							</SwiperSlide>
						)}
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default SwiperContainerPhone;