import React, {useMemo} from 'react';
import styled from '../../../styles/editorPage/workField.module.scss'
import {useSelector} from "react-redux";
import {getComponentCollection, getComponentCollectionMobile} from "../../../redux/selectors/editorSelectors";
import Container from "../components/Containers/Container";
import SwiperContainer from "../components/Containers/SwiperContainer";
import SwiperContainerPhone from "../components/Containers/SwiperContainerPhone";
import VideoContainer from "../components/Containers/VideoContainer";
import SwiperCategory from "../components/Containers/SwiperCategory";
import tabletBG from '../../../assets/tabletBG.png'
import phoneBG from '../../../assets/phoneBG.png'
import VideoContainerPrew from "../components/Containers/VideoContainerPrew";

const WorkField = ({isHide, device, isActivePage}) => {

	const componentCollection = useSelector(state => getComponentCollection(state));
	const componentCollectionMobile = useSelector(state => getComponentCollectionMobile(state));

	const width = useMemo(() => {
		if (device == 'tablet') {
			return styled.tablet
		}
		if (device == 'phone') {
			return styled.phone
		}
		return ''
	}, [device])

	const collection = useMemo(() => {
		return device === 'desktop' ? componentCollection : componentCollectionMobile
	}, [device, componentCollection, componentCollectionMobile])

	return (
		<div className={`${styled.workFieldContainer} ${isHide ? 'p_0' : ''}`}>
			{isHide && device !== 'desktop' ?
				<div style={{position: "absolute", width: '100%', justifyContent: 'center', display: 'flex', zIndex: 1000000, pointerEvents: 'none' }}>
				<img src={device === 'tablet' ? tabletBG : phoneBG} alt="" style={{width: device === 'tablet' ? '932px' : '492px', margin: 'auto', pointerEvents: 'none', height: device === 'tablet' ? 'auto' : '930px'}}/>

				</div>
				:
				<></>
			}


			<div className={`${(isHide && device === 'tablet') ? styled.tabletBG : ''} ${(isHide && device === 'phone') ? styled.phoneBG : ''}`}>
				<div className={`${styled.workField} ${width}`}>
					{collection.map((item, key) =>
						<React.Fragment key={key}>
							{(item.name === 'block-image' || item.name === 'block-image-w-half-h' || item.name === 'block-image-full-screen') &&
							<Container mainId={item.id} component={item} collection={collection} isActivePage={isActivePage} device={device}/>
							}
							{item.name === 'block-slider' &&
							<SwiperContainer mainId={item.id} component={item} collection={collection} isActivePage={isActivePage}/>}
							{item.name === 'block-slider-full' && <SwiperContainerPhone mainId={item.id} component={item} collection={collection} isActivePage={isActivePage}/>}

							{item.name === 'block-video' && item.components ?
								<VideoContainer mainId={item.id} component={item} collection={collection} isActivePage={isActivePage}/>
								: item.name === 'block-video' && !item.components ?
								<VideoContainerPrew mainId={item.id} component={item} collection={collection} isActivePage={isActivePage}/>
									: <></>
							}

							{item.name === 'block-slider-category' && <SwiperCategory mainId={item.id} component={item} collection={collection} isActivePage={isActivePage}/>}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default WorkField;
