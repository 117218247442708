export const getTemplates = (state) => {
	return state.templatesReducer.templates;
}

export const getActivePage = (state) => {
	return state.templatesReducer.activePage;
}

export const getPaginationData = (state) => {
	return state.templatesReducer.paginationData;
}
