export const getIsHide = (state) => {
	return state.editorReducer.isHide;
}

export const getDevice = (state) => {
	return state.editorReducer.device;
}

export const getLang = (state) => {
	return state.editorReducer.lang;
}

export const getLangList = (state) => {
	return state.editorReducer.langList;
}

export const getRightBarMenu = (state) => {
	return state.editorReducer.rightBarMenu;
}

export const getComponentCollection = (state) => {
	return state.editorReducer.componentCollection;
}

export const getComponentCollectionMobile = (state) => {
	return state.editorReducer.componentCollectionMobile;
}

export const getActiveComponent = (state) => {
	return state.editorReducer.activeComponent;
}

export const getNamePage = (state) => {
	return state.editorReducer.namePage;
}
export const getGuid = (state) => {
	return state.editorReducer.guid;
}

export const getIsActivePage = (state) => {
	return state.editorReducer.isActivePage;
}