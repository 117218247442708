import React, {useMemo} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {useSelector} from "react-redux";
import BorderActive from "../../workFields/BorderActive";
import {getLang} from "../../../../redux/selectors/editorSelectors";
import {useBorderActive} from "../../../../hooks/useBorderActive";

const TextBG = ({mainId, blockId, component, mouseOver, hover, isActivePage, collection}) => {

  const lang = useSelector(state => getLang(state));

  const {
    width, height, isHide, ref, resize,
    idActive, selectThisComponent,
  } = useBorderActive(component, [mainId, blockId, component.id])

  const select = (e) => {
    resize()
    selectThisComponent(e)
  }

  const classHover = useMemo(() => {
    if (!hover) {
      return ''
    }
    if (mouseOver) {
      return `${styled.textHover} ${styled.textHoverActive}`
    } else {
      return styled.textHover
    }
    return ''
  }, [hover, mouseOver])

  return (
    <div
      className={styled.frontSpaceWrapper}
      style={{
        alignSelf: component?.styles.alignSelf,
      }}
    >
      <div
        className='p-relative'
        style={{
          margin: component.styles.margin.join(' '),
        }}>
        {!isHide && idActive.length === 3 && component.id === idActive[2] && !isActivePage &&
          <BorderActive
            width={width}
            height={height}
            id={[mainId, blockId, component.id]}
            large={true}
            collection={collection}
            container={true}
          />
        }
        <div
          className={classHover}
          ref={ref}
          style={{
            fontWeight: component.styles.fontWeight,
            fontSize: component.styles.fontSize,
            fontFamily: component.styles.fontFamily,
            color: component.styles.color,
            background: component.styles.background,
            padding: component.styles.padding.join(' '),
          }}
          onClick={select}
        >
          {component.text[lang]}
        </div>
      </div>
    </div>
  );
};

export default TextBG;