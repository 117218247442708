import React, {useState} from 'react';
import styled from '../../styles/modals/modals.module.scss'
import {editorAPI} from "../../api/api";
import Modal from "antd/es/modal";
import Input from "antd/es/input";
import {setTemplates} from "../../redux/actions/templatesAction";
import {useDispatch, useSelector} from "react-redux";
import {getActivePage} from "../../redux/selectors/templatesSelectors";
import {setNamePage} from "../../redux/reducers/editorReducer";


export default function ModalNamePage({open, handleClose, setMessages, title, namePage='', id}) {
	const [name, setName] = useState('');
	const activePage = useSelector(state => getActivePage(state));
	const dispatch = useDispatch()


	const sendDataTemplate = async () => {
		if (!name) {
			setMessages(['Введите название шаблона'])
			return;
		}
		const send = namePage ? editorAPI.changeName : editorAPI.create
		const {api_success, api_messages} = await send({name: name}, id)
		if (api_success) {
			setName('')
			handleClose()
			dispatch(setTemplates(activePage))
			dispatch(setNamePage(name))
		}
		setMessages(api_messages)
	}

	const clickEnter = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault()
			sendDataTemplate()
		}
	}
	return (
		<div>
			<Modal
				visible={open}
				onOk={sendDataTemplate}
				onCancel={handleClose}
				title={title}
				okText={namePage ? "Изменить" : "Создать"}
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17"/>}
			>
					<form noValidate autoComplete="off" onKeyDown={clickEnter}>
						<div className={styled.inputLarge}>
							<Input
								placeholder="Название"
								defaultValue={namePage}
								onChange={(e) => setName(e.target.value)}

							/>
						</div>
					</form>
			</Modal>
		</div>
	);
}