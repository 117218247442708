import React, {useEffect, useState} from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Form, Select} from "antd";
import {editorAPI} from "../../../../api/api";

const SettingView = ({view, changeParams, isReplacement}) => {

	const [shops, setShops] = useState([])

	const getShops = async () => {
		const res = await editorAPI.getShops()
		if (res.api_success) {
			setShops(res.list)
		}
	}
	useEffect(() => {
		getShops()
	}, [])

	const selectUser = (val) => {
		changeParams('view', {user: val}, isReplacement)
	}
	const selectRestrictionsCountries = (val) => {
		if (val[0] === 'all' || val.length === 0) {
			changeParams('view', {
				countries: {
					checked: ['all'],
					all: true
				}
			},
				isReplacement)
		} else {
			changeParams('view', {
				countries: {
					checked: val,
					all: false
				}
			}, isReplacement)
		}
	}

	return (
		<div>
			{view
				?
				<div style={{marginTop: '15px'}}>
				<h3>Показывать</h3>

					<div className={`${styled.groupSelector}`}>
						<Form>
							<Form.Item
								name="age"
								style={{marginBottom: '10px'}}
							>
								<Select
									defaultValue={view.user}
									style={{
										width: 300,
									}}
									onChange={selectUser}
									getPopupContainer={trigger => trigger.parentNode}
								>
									<Select.Option value={'all'}>Для всех</Select.Option>
									<Select.Option value={'retail'}>Для розницы</Select.Option>
									<Select.Option value={'ordinary'}>Для обычных пользователей</Select.Option>
								</Select>
							</Form.Item>
						</Form>
						<div style={{fontWeight: '500', marginBottom: '7px'}}>Только для</div>
						<Form>
							<Form.Item
								name="age"

							>
								<Select
									defaultValue={view.countries.checked}
									style={{
										width: 300,
									}}
									onChange={selectRestrictionsCountries}
									mode="multiple"
									getPopupContainer={trigger => trigger.parentNode}
								>
									<Select.Option value={'all'}>Для всех</Select.Option>
									{shops.map((item, key) =>
										<Select.Option key={key} value={item.id}>{item.name}</Select.Option>
									)}

									<Select.Option value={false}>Для розницы</Select.Option>
								</Select>
							</Form.Item>
						</Form>

					</div>

				</div>
				:
				<></>
			}
		</div>
	);
};


export default SettingView;