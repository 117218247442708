import React, { useEffect, useMemo } from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import BorderActive from "../../workFields/BorderActive";
import Block from "../Blocks/Block";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useDispatch} from "react-redux";
import {setImageFull} from "../../../../redux/reducers/editorReducer";

const Container = ({mainId, component, collection, isActivePage, device}) => {

	const [refInView, inView] = useInView({threshold: 0.1})

	const {
		width, height, isHide,
		idActive, selectMainComponent,
		firstLoad, load, setChangeImage, ref,  resize
	} = useBorderActive(component, [component?.id])

	const select = (e) => {
		resize()
		selectMainComponent(e)
	}

	const dispatch = useDispatch()

	const heightComponent = useMemo(() => {
		if (component.name === 'block-image-w-half-h') {
			return styled.half_block
		}
		if (component.name === 'block-image-full-screen') {
			return styled.full_block
		}
		return ''
	}, [component])

	useEffect(() => {
		if (component?.name === 'block-image') {
			if (component?.components.length === 2 && !component?.fullImg) {
				dispatch(setImageFull(component.id, true))
			}
			if (component?.components.length !== 2 && component?.fullImg) {
				dispatch(setImageFull(component.id, false))
			}
		}
	}, [collection])

	return (
		<div
			className={`p-relative ${heightComponent}`}
			ref={ref}
		>
			{!isHide && idActive.length === 1 && idActive[0] === component?.id && !isActivePage &&
				<BorderActive
					width={width}
					height={height}
					id={[mainId]}
					collection={collection}
					btnDown={true}
					container={true}
				/>
			}
			<div
				ref={refInView}
				className={`${inView ? styled.mosaicCardShow : styled.mosaicCardHide} ${heightComponent}`}
			>
				<div
					className={`${styled.container} ${heightComponent}`}
					style={{
						gridGap: component?.styles.gridGap,
						gridTemplateColumns: component?.styles.gridTemplateColumns.join(' '),
					}}
					onMouseDown={select}
					onContextMenu={(e) => e.preventDefault()}
				>
					{ component?.components.map((item, key) =>
						<React.Fragment key={key}>
							<Block
								firstLoad={firstLoad}
								component={item}
								mainId={mainId}
								load={load}
								setChangeImage={setChangeImage}
								isActivePage={isActivePage}
								device={device}
								parentName={component?.name}
								heightComponent={heightComponent}
							/>
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default Container;