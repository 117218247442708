import {CHANGE_HIDE} from '../types'
import {CHANGE_LANG_LIST} from '../types'
import {CHANGE_DEVICE} from '../types'
import {ADD_COMPONENT_COLLECTION} from '../types'
import {CHANGE_ACTIVE_COMPONENT} from '../types'
import {CHANGE_STYLES_ACTIVE_COMPONENT} from '../types'
import {DELETE_ACTIVE_COMPONENT} from '../types'
import {DELETE_SWIPER_COMPONENT} from '../types'
import {ADD_BLOCK} from '../types'
import {ADD_BLOCK_SWIPER} from '../types'
import {ADD_ELEMENT} from '../types'
import {ADD_ELEMENT_REPLACEMENT} from '../types'
import {ADD_SLICED} from '../types'
import {CHANGE_LANG} from '../types'
import {CHANGE_RIGHTBAR_MENU} from '../types'
import {CHANGE_POSITION} from '../types'
import {ADD_SAVE_COLLECTION} from '../types'
import {SET_IMAGE_FULL} from '../types'
import {COPY_PAGE_MOBILE} from '../types'
import {CHANGE_NAME_PAGE} from '../types'
import {DELETE_KEY_OBJECT} from '../types'
import {
	addNewStyle,
	deleteComponent,
	addBlock,
	addElement,
	changePosition,
	addBlockSwiper, deleteBlockSwiper, chageImageFull, addSliced, addElementReplacement, deleteKeyObject
} from "../helpers/editorHelpers";

let initialState = {
	guid: '',
	isHide: false,
	namePage: '',
	device: 'desktop',
	componentCollection: [],
	componentCollectionMobile: [],
	activeComponent: [],
	langList: [],
	lang: 'ru',
	rightBarMenu: false,
	isActivePage: false
};

const selectCollection = (state) => {
	if (state.device === 'desktop') {
		return 'componentCollection'
	}
	if (state.device === 'tablet' || state.device === 'phone') {
		return 'componentCollectionMobile'
	}
}

const selectCollectionForClone = (state) => {
	if (state.device === 'desktop') {
		return state.componentCollection
	}
	if (state.device === 'tablet' || state.device === 'phone') {
		return state.componentCollectionMobile
	}
}

const templatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_COMPONENT_COLLECTION:
			return {
				...state, [selectCollection(state)]: [...selectCollectionForClone(state), action.component]
			}
		case ADD_BLOCK:
			return {
				...state,
				[selectCollection(state)]: addBlock(selectCollectionForClone(state), state.activeComponent, action.block)
			}
		case ADD_BLOCK_SWIPER:
			return {
				...state,
				[selectCollection(state)]: addBlockSwiper(selectCollectionForClone(state), state.activeComponent, action.block)
			}
		case ADD_ELEMENT:
			return {
				...state,
				[selectCollection(state)]: addElement(selectCollectionForClone(state), state.activeComponent, action.element)
			}
		case ADD_ELEMENT_REPLACEMENT:
			return {
				...state,
				[selectCollection(state)]: addElementReplacement(selectCollectionForClone(state), state.activeComponent, action.element)
			}
		case ADD_SLICED:
			return {
				...state,
				[selectCollection(state)]: addSliced(
					selectCollectionForClone(state),
					state.activeComponent,
					action.sliced,
					state.lang,
					action.isReplacement
				)
			}
		case ADD_SAVE_COLLECTION:
			return {
				...state,
				guid: action.guid,
				componentCollection: action.schema,
				componentCollectionMobile: action.schema_mobile,
				namePage: action.name,
				isActivePage: action.active,
			}
		case CHANGE_HIDE:
			return {
				...state, isHide: action.isHide
			}
		case CHANGE_LANG:
			return {
				...state,
				lang: action.lang,
				activeComponent: [],
			}
		case CHANGE_LANG_LIST:
			return {
				...state, langList: action.langList
			}
		case CHANGE_RIGHTBAR_MENU:
			return {
				...state, rightBarMenu: action.rightBarMenu
			}
		case CHANGE_DEVICE:
			return {
				...state, device: action.device
			}
		case COPY_PAGE_MOBILE:
			return {
				...state, componentCollectionMobile: JSON.parse(JSON.stringify(state.componentCollection))
			}
		case CHANGE_NAME_PAGE:
			return {
				...state, namePage: action.name
			}
		case CHANGE_POSITION:
			return {
				...state,
				[selectCollection(state)]: changePosition(selectCollectionForClone(state), state.activeComponent, action.direction)
			}
		case CHANGE_ACTIVE_COMPONENT:
			return {
				...state, activeComponent: action.id
			}
		case CHANGE_STYLES_ACTIVE_COMPONENT:
			return {
				...state,
				[selectCollection(state)]: addNewStyle(
					selectCollectionForClone(state),
					state.activeComponent,
					state.lang,
					action.typeParams,
					action.params,
					action.replacement,
					action.replacementId,
				)
			}
		case SET_IMAGE_FULL:
			return {
				...state,
				[selectCollection(state)]: chageImageFull(selectCollectionForClone(state), action.id, action.isFull)
			}
		case DELETE_KEY_OBJECT:
			return {
				...state,
				[selectCollection(state)]: deleteKeyObject(
					selectCollectionForClone(state),
					state.activeComponent,
					action.key,
				)
			}
		case DELETE_ACTIVE_COMPONENT:
			return {
				...state, [selectCollection(state)]: deleteComponent(selectCollectionForClone(state), action.id, state.lang)
			}
		case DELETE_SWIPER_COMPONENT:
			return {
				...state, [selectCollection(state)]: deleteBlockSwiper(selectCollectionForClone(state), action.id, state.lang)
			}
		default:
			return state;
	}
}

export const setHide = (isHide) => ({type: CHANGE_HIDE, isHide});
export const setLang = (lang) => ({type: CHANGE_LANG, lang});
export const setLangList = (langList) => ({type: CHANGE_LANG_LIST, langList});
export const setRightBarMenu = (rightBarMenu) => ({type: CHANGE_RIGHTBAR_MENU, rightBarMenu});
export const setDevice = (device) => ({type: CHANGE_DEVICE, device});
export const setCopyMobile = () => ({type: COPY_PAGE_MOBILE});
export const setComponentCollection = (component) => ({type: ADD_COMPONENT_COLLECTION, component});
export const setBlock = (block) => ({type: ADD_BLOCK, block});
export const setBlockSwiper = (block) => ({type: ADD_BLOCK_SWIPER, block});
export const setPosition = (direction) => ({type: CHANGE_POSITION, direction});
export const setElement = (element) => ({type: ADD_ELEMENT, element});
export const setElementReplacement = (element) => ({type: ADD_ELEMENT_REPLACEMENT, element});
export const setSliced = (sliced, isReplacement) => ({type: ADD_SLICED, sliced, isReplacement});
export const setActiveComponent = (id) => ({type: CHANGE_ACTIVE_COMPONENT, id});
export const setDeleteActiveComponent = (id) => ({type: DELETE_ACTIVE_COMPONENT, id});
export const setDeleteSwiperComponent = (id) => ({type: DELETE_SWIPER_COMPONENT, id});
export const setDeleteKeyObject = (key) => ({type: DELETE_KEY_OBJECT, key});
export const setNamePage = (name) => ({type: CHANGE_NAME_PAGE, name});
export const setImageFull = (id, isFull) => ({type: SET_IMAGE_FULL, id, isFull});
export const setAddSaveJSON = (guid, schema, schema_mobile, name, active) => (
	{type: ADD_SAVE_COLLECTION, guid,  schema, schema_mobile, name, active}
);
export const setStylesActiveComponent = (typeParams, params, replacement, replacementId) => (
	{type: CHANGE_STYLES_ACTIVE_COMPONENT, typeParams, params, replacement, replacementId}
);

export default templatesReducer;

