import React, { useEffect, useMemo } from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Input, Radio, Select } from "antd";
import { Option } from "antd/es/mentions";
import rMarginIco from '../../../../assets/sprite/margin_r.svg'
import lMarginIco from '../../../../assets/sprite/margin_l.svg'
import bMarginIco from '../../../../assets/sprite/margin_b.svg'
import tMarginIco from '../../../../assets/sprite/margin_t.svg'


const SettingStyles = ({styles, changeParams, isReplacement, parentStylesTextSliced}) => {

  const options = ["Futura PT", "Bodoni Cyrillic"];
  const [inputSelect, setInputSelect] = React.useState('');
  const initialSort = ['selfStyle', 'alignSelf', 'margin', 'padding', 'color', 'borderColor', 'background', 'fontFamily', 'fontWeight', 'fontSize', 'fontSizeTime', 'fontSizeType', 'gridGap', 'gridTemplateColumns']

  console.log(parentStylesTextSliced)

  useEffect(() => {
    if (styles?.fontFamily) {
      setInputSelect(styles.fontFamily)
    }
  }, [])

  const sortStyles = useMemo(() => {
    let result = {}
    if (styles) {
      const propsKeys = Object.keys(styles)
      for (let key of initialSort) {
        if (propsKeys.includes(key))
          result = {...result, [key]: styles[key]}
      }
      return result
    }
    return null
  }, [styles])

  const selectName = (val) => {
    switch (val) {
      case 'gridGap':
        return 'Отступ блоков';
      case 'margin':
        return 'Отступы (px)'
      case 'padding':
        return 'Внутренние отступы (px)'
      case 'background':
        return 'Цвет Фона'
      case 'borderColor':
        return 'Цвет рамки';
      case 'color':
        return 'Цвет текста';
      case 'fontSize':
        return 'Размер';
      case 'fontSizeTime':
        return 'Размер(Цифры)';
      case 'fontSizeType':
        return 'Размер(Текст)';
      case 'fontWeight':
        return 'Ширина';
      case 'selfStyle':
        return 'Собственные стили';
      default:
        return "Нет таких значений";
    }
  }

  const getMarginName = (val) => {
    switch (val) {
      case 0:
        return tMarginIco;
      case 1:
        return rMarginIco
      case 2:
        return bMarginIco;
      case 3:
        return lMarginIco;
      default:
        return "Нет таких значений";
    }
  }


  const setArrayParams = (name, array, val, key) => {
    const newArray = [...array]
    newArray.splice(key, 1, val)
    changeParams('styles', {[name]: newArray}, isReplacement)
  }

  const changeText = (val) => {
    if (val.fontFamily === 'Bodoni Cyrillic') {
      changeParams('styles', {fontWeight: 400}, isReplacement)
    }
    changeParams('styles', val, isReplacement)
  }

  const changeSelfStyles = (checked) => {
    if (checked) {
      changeParams('styles', {selfStyle: checked}, isReplacement)
    } else {
      changeParams(
        'styles',
        {
          selfStyle: checked,
          color: parentStylesTextSliced?.color,
          fontFamily: parentStylesTextSliced?.fontFamily,
          fontWeight: parentStylesTextSliced?.fontWeight,
          fontSize: parentStylesTextSliced?.fontSize,
        },
        isReplacement
      )
    }
  }

  return (
    <div>
      {sortStyles
        ?
        <div>
          {Object.entries(sortStyles).map((item, key) =>
            <div key={key}>
              {item[0] === 'fontSize' || item[0] === 'fontSizeTime' || item[0] === 'fontSizeType' || item[0] === 'gridGap'
                ?
                <div className={styled.textAndButtoms} style={{margin: '10px 15px'}}>
                  <div className={styled.label}>
                    {selectName(item[0])}
                  </div>
                  <div className={`${styled.buttomItems}`}>
                    <Input
                      style={{width: '75px'}}
                      size={'small'}
                      defaultValue={+item[1].split('px')[0]}
                      onChange={(e) => changeParams('styles', {[item[0]]: Number(e.target.value) + 'px'}, isReplacement)}
                    />
                  </div>
                </div>
                :
                item[0] === 'borderColor' || item[0] === 'color' || item[0] === 'background'
                  ?
                  <div className={styled.textAndButtoms} style={{margin: '15px'}}>
                    <div className={styled.label}>
                      {selectName(item[0])}
                    </div>
                    <div
                      className={styled.buttomItems}
                    >
                      <div>
                        <input
                          className={styled.inputColor}
                          type="color"
                          value={item[1]}
                          onChange={(e) => changeParams('styles', {[item[0]]: e.target.value}, isReplacement)}
                        />
                      </div>
                      <div>
                        {item[1]}
                      </div>
                    </div>
                  </div>
                  :
                  item[0] === 'selfStyle'
                    ?
                    <div className={styled.rightStylesMainMargin} key={styles.selfStyle}>
                      <div className={styled.checkbox}>
                        <Checkbox
                          checked={styles.selfStyle}
                          onChange={(e) => changeSelfStyles(e.target.checked)}
                        >
                          {selectName(item[0])}
                        </Checkbox>
                      </div>
                    </div>
                  :
                  item[0] === 'margin' || item[0] === 'padding'
                    ?
                    <div>
                      <h3>{selectName(item[0])}</h3>
                      <div className={styled.listBlock}>
                        {item[1].map((val, key) =>
                          <div className={styled.element} key={key}>
                            <div className={styled.label}>
                              <img src={getMarginName(key)} alt={item[0]} />
                            </div>
                            <div className={styled.inputWrapper}>
                              <Input
                                size='small'
                                defaultValue={+val.split('px')[0]}
                                onChange={
                                  (e) => setArrayParams(item[0], item[1], Number(e.target.value) + 'px', key)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    :
                    item[0] === 'gridTemplateColumns'
                      ?
                      <div>
                        <h3>Размеры блоков</h3>
                        <div className={styled.listBlock}>
                          {item[1].map((val, key) =>
                            <div className={styled.element} key={key}>
                              <div className={styled.label}>
                                B{key + 1}
                              </div>
                              <div className={styled.inputWrapper}>
                                <Select
                                  style={{
                                    width: 110,
                                  }}
                                  defaultValue={item[1]}
                                  size={'small'}
                                  onChange={(e, target) => setArrayParams(item[0], item[1], target.value, key)}
                                  getPopupContainer={trigger => trigger.parentNode}
                                >
                                  <Option value="0.5fr">0.5fr</Option>
                                  <Option value="1fr">1fr</Option>
                                  <Option value="1.5fr">1.5fr</Option>
                                  <Option value="2fr">2fr</Option>
                                  <Option value="2fr">3fr</Option>
                                  <Option value="2fr">7fr</Option>
                                </Select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      :
                      item[0] === 'alignSelf'
                        ?
                        <div>
                          <h3>Расположение элементов</h3>
                          <div className={styled.groupButtons}>
                            <Radio.Group onChange={(e) => changeParams('styles', {alignSelf: e.target.value}, isReplacement)}
                                         defaultValue={item[1]}>
                              <Radio.Button value="start"><span
                                className="icon-text-align-left fs-17 " /></Radio.Button>
                              <Radio.Button value="center"><span
                                className="icon-text-align-center fs-17 " /></Radio.Button>
                              <Radio.Button value="end"><span className="icon-text-align-right fs-17 " /></Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                        :
                        item[0] === 'fontFamily'
                          ?
                          <div className={styled.block}>
                            <Select
                              showSearch
                              style={{
                                width: 315,
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children.includes(input)}
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                              defaultValue={item[1]}
                              onChange={(event, item) => {
                                changeText({fontFamily: item.value})
                              }}
                              getPopupContainer={trigger => trigger.parentNode}
                            >
                              <Option value="Bodoni Cyrillic">Bodoni Cyrillic</Option>
                              <Option value="Futura PT">Futura PT</Option>
                            </Select>
                          </div>
                          :
                          item[0] === 'fontWeight'
                            ?
                            <div className={styled.textAndSwitch}>
                              <div className={styled.label}>
                                {selectName(item[0])}
                              </div>
                              <div
                                className={styled.inputWrapper}
                              >
                                <div className={`${styled.buttomItems}`}>
                                  {styles?.fontFamily === "Futura PT"
                                    ?
                                    <Select
                                      style={{
                                        width: 75,
                                      }}
                                      value={item[1]}
                                      size={'small'}
                                      onChange={(e, item) => changeParams('styles', {fontWeight: item.value}, isReplacement)}
                                      getPopupContainer={trigger => trigger.parentNode}
                                    >
                                      <Option value={300}>300</Option>
                                      <Option value={400}>400</Option>
                                      <Option value={500}>500</Option>
                                      <Option value={900}>900</Option>
                                    </Select>
                                    :
                                    styles?.fontFamily === "Bodoni Cyrillic"
                                      ?
                                      <Select
                                        style={{
                                          width: 75,
                                        }}
                                        value={item[1]}
                                        size={'small'}
                                        onChange={(e, item) => changeParams('styles', {fontWeight: item.value}, isReplacement)}
                                        getPopupContainer={trigger => trigger.parentNode}
                                      >
                                        <Option value={400}>400</Option>
                                      </Select>
                                      :
                                      <></>
                                  }
                                </div>
                              </div>
                            </div>
                            :
                            <></>
              }
            </div>
          )}
        </div>
        :
        <></>
      }
    </div>
  );
};

export default SettingStyles;