import React, {useEffect, useMemo, useState} from 'react';
import SettingStyles from "./Settings/SettingStyles";
import SettingStylesMain from "./Settings/SettingStylesMain";
import SettingAnimation from "./Settings/SettingAnimation";
import SettingImages from "./Settings/SettingImages";
import SettingVideo from "./Settings/SettingVideo";
import SettingText from "./Settings/SettingText";
import SettingLink from "./Settings/SettingLink";
import AddComponent from "./Add/AddComponent";
import styled from '../../../styles/editorPage/rightBarEditor.module.scss'
import { Menu } from 'antd';
import {useSelector} from "react-redux";
import { getActiveComponent, getLang } from "../../../redux/selectors/editorSelectors";
import SettingView from "./Settings/SettingView";
import SettingCategorySlider from "./Settings/SettingCategorySlider";
import SettingDatePiker from "./Settings/SettingDatePiker";
import SettingTextSliced from "./Settings/SettingTextSliced";
import AddReplacement from "./Add/AddReplacement";

const SideStyles = (props) => {
	const {
		data,
		dataReplacement,
		changeParams,
		setMenuActive,
		parentComponent,
		parentStylesTextSliced
	} = props;

	const itemsMenu = ['general', 'components', 'other']
	const [activeMenu, setActiveMenu] = useState('general')
	const [disabledItems, setDisabledItems] = useState([])
	const [rerender, setRerender] = useState(0)
	const activeComponent = useSelector(state => getActiveComponent(state));
	const lang = useSelector(state => getLang(state));

	const dataActual = useMemo(() => {
		if (dataReplacement && (dataReplacement?.deadline || dataReplacement?.isPreview)) {
			if (activeComponent.length === 4) {
				const a = dataReplacement[lang].filter(item => item.id === activeComponent[3])[0]
				return a
			}
			return dataReplacement
		}
		return data
	}, [dataReplacement, data])

	const getDisabledComponents = useMemo(() => {
		return dataActual?.name === 'block' ||
			dataActual?.name === 'block-image' ||
			dataActual?.name === 'swiper_phone' ||
			dataActual?.name === 'block-slider-full' ||
			dataActual?.name === 'block-slider' ||
			dataActual?.name === 'text_sliced' ||
			dataActual?.name === 'text' ||
			dataActual?.name === 'block_text_bg' ||
			dataActual?.name === 'button_outline' ||
			dataActual?.name === 'countdown_timer'
	}, [dataActual])

	const getDisabledGeneral = useMemo(() => {
		return !dataActual?.image
			&& !dataActual?.datePiker
			&& !dataActual?.styles?.justifyContent
			&& !dataActual?.link
			&& !dataActual?.video
			&& !dataActual?.view
			&& !dataActual?.country
	}, [dataActual])

	const defaultKey = useMemo(() => {
		let arrDisabled = [];

		if (getDisabledGeneral) {
			arrDisabled = [...arrDisabled, 'general']
		}
		if (!getDisabledComponents) {
			arrDisabled = [...arrDisabled, 'components']
		}
		if (!dataActual?.text && !dataActual?.styles && !dataActual?.hover) {
			arrDisabled = [...arrDisabled, 'other']
		}
		setDisabledItems(arrDisabled)
		if (arrDisabled.length < 3) {
			for (let i of itemsMenu) {
				if (!arrDisabled.includes(i)) {
					setActiveMenu(i)
					return i
				}
			}
		}
		setRerender(Math.random())
		return ''

	}, [activeComponent, dataReplacement])

	const getKey = (key) => {
		if (activeComponent.length) {
			return activeComponent[activeComponent.length - 1] + key
		}
		return key
	}


	return (
		<div style={{marginBottom: '50px'}}>
			<div className={styled.sideHead}>
				<div className={styled.title}>Свойства компонента</div>
				<div className={styled.close} onClick={setMenuActive}><span className="f-icon icon-cross fs-17 hover-blue "/></div>
			</div>
			<Menu
				mode="horizontal"
				defaultSelectedKeys={itemsMenu}
				onSelect={({key}) => setActiveMenu(key)}
				selectedKeys={[activeMenu]}
				style={{marginRight: '10px'}}
			>
				<Menu.Item key="general" disabled={disabledItems.includes("general")}>
					Общие
				</Menu.Item>
				<Menu.Item key="components" disabled={disabledItems.includes("components")} >
			    Компоненты
				</Menu.Item>
				<Menu.Item key="other" disabled={disabledItems.includes("other")}>
					Другое
				</Menu.Item>
			</Menu>
			<div style={{marginBottom: '20px'}}/>
			{activeMenu === 'general' ?
				<>
					<SettingImages
						image={dataActual?.image}
						changeParams={changeParams}
						name={parentComponent}
						isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
					/>
					<SettingLink
						key={getKey(2)}
						link={dataActual?.link}
						changeParams={changeParams}
						isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
					/>
					<SettingStylesMain key={getKey(3)} styles={dataActual?.styles} changeParams={changeParams} isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}/>
					<SettingVideo
						video={dataActual?.video}
						isPlay={dataActual?.isPlay}
						changeParams={changeParams}
						isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
					/>
					<SettingView key={getKey(4)} view={dataActual?.view} changeParams={changeParams} isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}/>
					<SettingCategorySlider
						key={getKey(5)}
						category={dataActual?.category}
						lang={dataActual?.lang}
						shop={dataActual?.shop}
						count={dataActual?.count}
						changeParams={changeParams}
						isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
					/>
					<SettingDatePiker
						key={getKey(6)}
						datePiker={dataActual?.datePiker}
						changeParams={changeParams}
						isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
					/>
				</>
				: activeMenu === 'components' ?
					<>
					<AddComponent
						name={dataActual?.name}
						isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
					/>
					<AddReplacement
						key={getKey(7)}
						name={data?.name}
						dataReplacement={dataReplacement}
						data={data}
						changeParams={changeParams}
					/>
					</>
					: activeMenu === 'other' ?
						<>
							<SettingText
								key={getKey(8)}
								text={dataActual?.text}
								changeParams={changeParams}
								isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
							/>
							<SettingTextSliced
								key={getKey(lang)}
								textSliced={dataActual?.textSliced}
								changeParams={changeParams}
								isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
							/>
							<SettingStyles
								key={getKey(9)}
								styles={dataActual?.styles}
								changeParams={changeParams}
								isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
								parentStylesTextSliced={parentStylesTextSliced}
							/>
							<SettingAnimation
								hover={dataActual?.hover}
								changeParams={changeParams}
								isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
							/>
						</>
					:
				<></>
			}
			{!defaultKey ?
				<div className={styled.emptyStyle}>
					Не выбран ни один компонент
				</div>
				:
				<></>
			}
		</div>
	);
};

export default SideStyles;