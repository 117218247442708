import React, { useEffect, useMemo } from 'react';
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";
import { Checkbox, Select } from "antd";
import { Option } from "antd/es/mentions";
import SettingDatePiker from "../Settings/SettingDatePiker";
import { useDispatch, useSelector } from "react-redux";
import { getActiveComponent, getLangList } from "../../../../redux/selectors/editorSelectors";
import {
  setDeleteKeyObject,
  setElementReplacement,
  setStylesActiveComponent
} from "../../../../redux/reducers/editorReducer";
import {
  blockTextBg,
  button_outline,
  countdownTimer, text,
  textSliced
} from "../../../../helpers/initialStylesForComponents";

const AddReplacement = (props) => {
  const {
    name,
    dataReplacement,
    data
  } = props;

  const activeComponent = useSelector(state => getActiveComponent(state))
  const langList = useSelector(state => getLangList(state));

  const dispatch = useDispatch()

  const isShowSetting = useMemo(() => {
    return (
      name === 'text_sliced' ||
      name === 'text' ||
      name === 'block_text_bg' ||
      name === 'button_outline' ||
      name === 'countdown_timer'
    )
  }, [name])

  const getElement = (name) => {
    if (name === 'text') return text(langList)
    if (name === 'text_sliced') return textSliced(langList)
    if (name === 'block_text_bg') return blockTextBg(langList)
    if (name === 'button_outline') return button_outline(langList)
    if (name === 'countdown_timer') return countdownTimer()
  }

  const changeParams = (typeParams, params) => {
    dispatch(setStylesActiveComponent(typeParams, params, true)) // replacement: true
  }

  const changeParamsDelete = (typeParams, params) => {
    dispatch(setStylesActiveComponent(typeParams, params))
  }


  const getFutureDate = () => {
    let date = new Date();
    date.setDate(date.getDate() + 3)
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    return `${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]} ${date.getFullYear()}`
  }


  const addComponentReplacement = (name) => {
    let element
    if (name) {
      element = getElement(name)
      element = {
        ...element,
        id: activeComponent[2],
        datePikerReplacement: {
          date: getFutureDate(),
          time: '00:00:00',
        },
        deadline: false,
        isPreview: true
      }
      dispatch(setElementReplacement(element))
    } else {
      dispatch(setElementReplacement(null))
    }

  }

  const changeSelectedTimeDelete = (val) => {
    if (val) {
      dispatch(setStylesActiveComponent(
        'datePikerDelete',
        {
          date: getFutureDate(),
          time: '00:00:00'
        })
      )
    } else {
      dispatch(setDeleteKeyObject('datePikerDelete'))
    }
  }

  //setDeleteKeyObject

  return (
    <React.Fragment>
      {isShowSetting &&
        <React.Fragment>
          <div>
            <h3 className={'mb--5 mt-15'}>Выбрать компонент на замену</h3>
            <div className={styled.block}>
              <Select
                style={{
                  width: 315,
                }}
                defaultValue={dataReplacement?.name ? dataReplacement.name : false}
                onChange={(event, item) => addComponentReplacement(item.value)}
              >
                <Option value={false}>Не выбрано</Option>
                <Option value="text">Текст</Option>
                <Option value="block_text_bg">Текст с фоном</Option>
                <Option value="text_sliced">Текст по блокам</Option>
                <Option value="button_outline">Кнопка</Option>
                <Option value="countdown_timer">Таймер</Option>
              </Select>
            </div>

          </div>
          {dataReplacement &&
           <React.Fragment>
             <div className={`mx-15`}>
               <div className={styled.checkbox}>
                 <Checkbox
                   checked={dataReplacement.isPreview}
                   onChange={(e) => changeParams('isPreview', e.target.checked)}
                 >
                   Показать
                 </Checkbox>
               </div>
             </div>
             <SettingDatePiker datePiker={dataReplacement.datePikerReplacement} changeParams={changeParams} type={'datePikerReplacement'} />
             {dataReplacement.deadline &&
               <div className={`${styled.deadline} m-15`}>Компонент был изменен</div>
             }
           </React.Fragment>
          }
          <div>
            <h3 className={'mb--5 mt-15'}>Удалить компонент через время?</h3>
            <div className={styled.block}>
              <Select
                style={{
                  width: 315,
                }}
                defaultValue={!!data?.datePikerDelete}
                onChange={(event, item) => changeSelectedTimeDelete(item.value)}
              >
                <Option value={false}>Не надо</Option>
                <Option value={true}>Удалить</Option>
              </Select>
            </div>
            {data.datePikerDelete ?
              <SettingDatePiker datePiker={data.datePikerDelete} changeParams={changeParamsDelete} type={'datePikerDelete'} />
              : <></>
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default AddReplacement;