import React, {useEffect, useState} from 'react';
import Button from "antd/es/button";
import PageHeader from "antd/es/page-header";
import styled from '../../styles/templatesPage/pageHeader.module.scss'
import ModalNamePage from "../Modals/ModalNamePage";

const CreateTemplateButton = ({setMessages}) => {

	const [openModal, setOpenModal] = useState(false);
	const handleClose = () => setOpenModal(false);
	const handleOpen = () => setOpenModal(true);

	return (
		<React.Fragment>
			<PageHeader
				ghost={false}
				title="Constructor"
				backIcon={false}
				className={styled.pageHeader}
				extra={[
					<Button key="1" type="primary" ghost onClick={handleOpen} icon={<span className="icon-plus fs-11 mr-7"/>}>
						Добавить страницу
					</Button>,
				]}
			>
			</PageHeader>
			<ModalNamePage open={openModal} handleClose={handleClose} title={'Новая страница'} setMessages={setMessages}/>

		</React.Fragment>
	);
};

export default CreateTemplateButton;