import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Input } from "antd";

const SettingVideo = ({video, isPlay, changeParams, isReplacement}) => {

	const changeVideo = (val) => {
		if (!val) {
			val = 'https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25'
		}
		changeParams('video', val, isReplacement)
	}

	const changeIsPlay = (val) => {
		changeParams('isPlay', val, isReplacement)
	}

	return (
		<div>
			{video
				?
				<div style={{marginTop: '15px'}}>
					<h3>Видео</h3>
					<div>
						<div style={{width: '100%'}}>
							<div className={styled.linkContainer}>
								<Input
									placeholder="path"
									value={video}
									onChange={(e) => changeVideo(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
				:
				<></>
			}
			{isPlay !== undefined
				?
				<div className={`mx-15 mt-5`}>
					<div className={styled.checkbox}>
						<Checkbox
							checked={isPlay}
							onChange={(e) => changeIsPlay(e.target.checked)}
						>
							Воспрпоизвести
						</Checkbox>
					</div>
				</div>
				:
				<></>
			}
		</div>
	);
};

export default SettingVideo;