import * as React from 'react';
import styled from '../../styles/editorPage/headerEditor.module.scss'
import {setCopyMobile, setHide} from "../../redux/reducers/editorReducer";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Button, PageHeader, Tooltip} from 'antd';
import SettingDevice from "./RightBar/Settings/SettingDevice";
import {getComponentCollection, getComponentCollectionMobile} from "../../redux/selectors/editorSelectors";
import SettingLang from "./RightBar/Settings/SettingLang";
import { useParams} from "react-router";
import {editorAPI} from "../../api/api";
import ModalNamePage from "../Modals/ModalNamePage";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/es/modal";


export default function HeaderEditor(props) {
	const {guid, isHide, device, setMessages, namePage, isActivePage} = props

	const {pageId} = useParams()
	const componentCollection = useSelector(state => getComponentCollection(state));
	const componentCollectionMobile = useSelector(state => getComponentCollectionMobile(state));
	const [openLangs, setOpenLangs] = useState(false);

	const dispatch = useDispatch()
	const {confirm} = Modal;

	const showPage = () => {
		dispatch(setHide(true))
	}
	const handleClose = () => setOpenLangs(false)


	const saveSchema = async () => { /*{api_success, api_messages}*/
		const {api_messages} = await editorAPI.saveSchema(pageId, componentCollection, componentCollectionMobile)
		setMessages(api_messages)
	}

	const showModalConfirm = (id) => {
		confirm({
			title: 'Подтвердить',
			icon: <ExclamationCircleOutlined/>,
			content: 'Вы уверены, что хотите перенести все настройки данной страницы на мобильную версию?',
			onOk() {
				dispatch(setCopyMobile())
			}
		});
	}

	const goToLichiPreview = () => {
		window.open(`https://lichi.com/ru/ru?hp_id=${guid}`)
	}

	return (
		<div className={`${styled.headerEditor} ${isHide ? 'hide' : ''}`} >
			<PageHeader
				ghost={false}
				onBack={() => window.history.back()}
				title={namePage}
				subTitle={"#" + pageId}
				extra={[
					<div className={'hover-blue custom-ant'} 	key='1'>
						<Button
							key='1'
							type={'link'}
							onClick={goToLichiPreview}
						>
							<span className="f-icon icon-exit-up fs-17 mr-10"/> Предпросмотр
						</Button>
					</div>,
					<div className={'hover-blue custom-ant'} 	key='2'>
						<Button
							key='2'
							type="dashed"
							onClick={showPage}
						>
							<span className="f-icon icon-eye fs-17 mr-10"/> Посмотреть
						</Button>
					</div>,
					<SettingDevice device={device} key='3'/>,
					<SettingLang key='4'/>,
					<>
					{!isActivePage && <div className={'custom-ant'} key='5'>
						<Button
							key='5'
							type="primary"
							ghost
							onClick={saveSchema}
						>
							<span className="f-icon icon-floppy-disk fs-17 mr-10"/> Сохранить
						</Button>
					</div>}
					</>,
					<>
						{(!isActivePage && device === 'desktop') && <div className={'custom-ant'} key={'6'}>
							<Tooltip title="Перенести для мобильной версии">
								<Button
									key='6'
									icon={<span className="f-icon icon-copy fs-17"/>}
									onClick={showModalConfirm}
								/>
							</Tooltip>
						</div>}
					</>,
					<>
					{!isActivePage && <div className={'custom-ant'}	key='7'>
						<Button
							key='7'
							icon={<span className="f-icon icon-cog fs-17"/>}
							onClick={() => {
								setOpenLangs(true)
							}}
						/>
					</div>}
					</>,
				]}
			/>
			<ModalNamePage open={openLangs} handleClose={handleClose} setMessages={setMessages}
											 title={'Редактровать страницу'} id={pageId} namePage={namePage}/>
		</div>

	);
}
