import React, {useEffect, useState} from 'react';
import {Form, InputNumber, Select} from "antd";
import {editorAPI} from "../../../../api/api";
import {useDispatch} from "react-redux";

const keyShops = {
	1: 'kz',
	2: 'ru',
	3: 'de',
	4: 'ww',
	5: 'eu',
	6: 'uk',
	7: 'us',
	8: 'ar',
}

const SettingCategorySlider = ({shop, lang, category, count, changeParams, isReplacement}) => {

	const [currentShop, setCurrentShop] = useState('');
	const [params, setParams] = useState({});
	const [shops, setShops] = useState([]);
	const [categories, setCategories] = useState([]);

	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com/site/info`, requestOptions)
			.then(response => response.json())
			.then(result => result.api_data.data.info.shop)
			.catch(error => console.log('error', error));
		setParams(res)
	}
	const getShops = async () => {
		const res = await editorAPI.getShops()
		if (res.api_success) {
			setShops(res.list)
		}
	}
	const getCategories = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com//category/get_category_list?lang=1&shop=2`, requestOptions)
			.then(response => response.json())
			.then(result => result)
			.catch(error => console.log('error', error));
		setCategories(res.api_data.aData)
	}

	useEffect( () => {
		getParams()
		getShops()
	}, [])
	useEffect( () => {
		getCategories()
	}, [shop])
	useEffect( () => {
		if (currentShop) {
			changeParams('shop', {
				lang: params[currentShop]?.language_def_id,
				shop: params[currentShop]?.id,
			}, isReplacement)
		}
	}, [currentShop, params])

	return (
		<div>
			{shop && lang && count && category
				?
				<div>
				<h3>Настройки слайдера:</h3>

					<Form style={
						{ margin: '10px 15px'}
					}>
						<Form.Item
							style={{marginBottom: '15px'}}
							name={'country'}
							label="Укажите страну"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Select
								style={{width: '100%'}}
								defaultValue={keyShops[shop]}
								onChange={(e, item) => setCurrentShop(item.value)}
								getPopupContainer={trigger => trigger.parentNode}
							>
								{shops.map((item, key) =>
									<Select.Option key={key} value={item.id}>{item.name}</Select.Option>
								)}
							</Select>
						</Form.Item>
						<Form.Item
							style={{marginBottom: '15px'}}
							name={'category'}
							label="Укажите категорию"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Select
								style={{width: '100%'}}
								defaultValue={category}
								onChange={(e, item) => changeParams( 'category', item.value, isReplacement)}
								getPopupContainer={trigger => trigger.parentNode}
							>
								{categories?.length && categories.map((item, key) =>
									<Select.Option key={key} value={item.url}>{item.name}</Select.Option>
								)}
							</Select>
						</Form.Item>
						<Form.Item
							style={{marginBottom: '15px'}}
							name={'count'}
							label="Укажите кол-во блоков"
							rules={[
								{
									required: true,
								},
							]}
						>
							<InputNumber
								style={{width: '100%'}}
								defaultValue={count}
								onChange={(val) => 	changeParams( 'count', val, isReplacement)}
							/>
						</Form.Item>
					</Form>

				</div>
				:
				<></>
			}
		</div>
	);
};


export default SettingCategorySlider;