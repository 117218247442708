import React, { useEffect } from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Form, Input} from "antd";

const InputText = ({changeParams, item, isReplacement}) => {

	return (
		<Form.Item
			style={{marginBottom: '5px'}}
			name={item[0]}
			label={item[0]}
			rules={[
				{
					required: true,
				},
			]}
		>
			<Input
				defaultValue={item[1]}
				size={'small'}
				type="text"
				onChange={(e) => changeParams('text', {[item[0]]: e.target.value}, isReplacement)}
			/>
		</Form.Item>
	)
}

const SettingText = ({text, changeParams, name, isReplacement}) => {


	const layout = {
		labelCol: {
			span: 3,
		},
		wrapperCol: {
			span: 21,
		},
	};

	return (
		<div>
			{text
				?
				<div>
					<div className={styled.textI18n}>
						<h3>Текст</h3>
						<Form {...layout} style={{width: '300px', margin: '0 15px'}}>
							{Object.entries(text).map((item, key) =>
								<React.Fragment key={key}>
									<InputText changeParams={changeParams} item={item} isReplacement={isReplacement}/>
								</React.Fragment>
							)}
						</Form>
					</div>
				</div>
				:
				<></>
			}
		</div>
	);
};


export default SettingText;
