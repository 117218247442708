import React, {useEffect, useRef, useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {useSelector} from "react-redux";
import {getDevice} from "../../../../redux/selectors/editorSelectors";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Controller} from "swiper";
import BorderActive from "../../workFields/BorderActive";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useMediaQuery} from "react-responsive";
import sliderLastBlock from '../../../../assets/sliderLastBlock.jpg'

SwiperCore.use([Controller]);

const WrapperBlock = ({refImage, item, phone, device}) => {

	const [mouseOver, setMouseOver] = useState(false)
	const setAnimate = (flag) => {
		if (device === 'desktop') {
			setMouseOver(flag)
		}
	}

	return (
		<div className='p-relative'>
			<div
				className={styled.itemContainer}
				onMouseOver={() => setAnimate(true)}
				onMouseOut={() => setAnimate(false)}
			>
				<div className={styled.imgSize}>
					<img
						ref={refImage}
						src={item.photos[0].thumbs['768_1024']} alt=""
						style={{width: phone ? '100%' : 'none'}}
					/>
				</div>
				<div
					className={`${styled.frontSpace} `}
					style={{
						backgroundImage: `url(${item.photos[0].thumbs['768_1024']})`,
						justifyContent: 'end'
					}}
				>
					<div
						className={ mouseOver
							? `${styled.cardColor} ${styled.cardColorsHover}`
							: `${styled.cardColor}`
						}
					>
						<div
							className={ item.colors.current.value == 'FFFFFF'
								? `${styled.color} ${styled.light}`
								: `${styled.color}`
							}
							style={{background: `#${item.colors.current.value}`}}
						/>
						{item.colors.other.map((item, key) => {
								return  <div
									key={key}
									className={ item.value == 'FFFFFF'
										? `${styled.color} ${styled.light}`
										: `${styled.color}`
									}
									style={{background: `#${item.value}`}}
								/>
							}
						)}
					</div>
					{device === 'desktop' ?
						<div
							className={styled.frontSpaceWrapper}
							style={{
								alignSelf: "center",
								width: '80%'

							}}
						>
							<div
								style={{marginBottom: '30px', color: '#FFFFFF',overflow: 'hidden', textOverflow: 'ellipsis',  textWrap: 'nowrap', whiteSpace: 'nowrap', }}
							>
								{item.name.toUpperCase()}
							</div>
							<div
								style={{marginBottom: '30px',  color: '#FFFFFF'}}
							>
								{item.format_price[1]}
							</div>
						</div>
						:
						<></>
					}
					{/*					{component?.components.map((item, key) =>
												<React.Fragment key={key}>
													{item.name === 'button_outline' && <ButtonOutline mainId={mainId} blockId={component.id} component={item} isActivePage={isActivePage}/>}
													{item.name === 'text' && <Text mainId={mainId} blockId={component.id} component={item} mouseOver={mouseOver} hover={component?.hover?.hoverText} isActivePage={isActivePage}/>}
												</React.Fragment>
											)}*/}
				</div>
			</div>
		</div>
	)
}

const SwiperCategory = ({mainId, component, collection, isActivePage}) => {


	const [params, setParams] = useState([])

	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com/category/get_category_product_list?category=${component.category}&limit=${component.count}&lang=${component.lang}&shop=${component.shop}`, requestOptions)
			.then(response => response.json())
			.then(result => result)
			.catch(error => console.log('error', error));
		setParams(res.api_data.aProduct)
	}

	useEffect( () => {
		getParams()
	}, [component])

	const [refInView, inView] = useInView({threshold: 0.1})

	const [firstSwiper, setFirstSwiper] = useState(null);

	const refImage = useRef()
	const device = useSelector(state => getDevice(state));
	const k5 = useMediaQuery({minWidth: 3840})
	const k4 = useMediaQuery({minWidth: 2160, maxWidth: 3839.98})
	const tableBig = useMediaQuery({minWidth: 768, maxWidth: 1199.98})
	const phone = useMediaQuery({maxWidth: 575.98})
	const slidePerView = k4
		? 7.3
		: k5
			? 8.3
			: tableBig
				? 4.3
				: device === 'tablet'
					? 3.3
					: device === 'phone'
						? 2.3
						: 4.4

	const {
		width, height, isHide,
		idActive, selectMainComponent,
		ref, resize
	} = useBorderActive(component, [component?.id])

	const select = (e) => {
		resize()
		selectMainComponent(e)
	}

	return (
		<div
			className='p-relative'
			ref={ref}
		>
			{!isHide && idActive.length === 1 && idActive[0] === component.id && !isActivePage &&
			<BorderActive
				width={width}
				height={height}
				id={[mainId]}
				collection={collection}
				btnDown={true}
				container={true}
			/>
			}
			<div
				onMouseDown={select}
				onContextMenu={(e) => e.preventDefault()}
				ref={refInView}
				className={`${styled.swiperContainer} ${inView ? styled.mosaicCardShow : styled.mosaicCardHide}`}
			>
				{/*swiper.updateSlides()*/}
				<Swiper
					className="mySwiper"
					onSwiper={setFirstSwiper}
					controller={{control: firstSwiper}}
					slidesPerView={slidePerView}
					spaceBetween={0}
					slidesPerGroup={phone ? 1 : 4}
					speed={phone ? 300 : 1300}
				>
					{ params.map((item, key) =>
						<SwiperSlide key={key}>
							<WrapperBlock refImage={refImage} item={item} phone={phone} device={device}/>
						</SwiperSlide>

					)}
					<SwiperSlide >
						<div className='p-relative'>
							<div
								className={styled.itemContainer}
							>
								<div className={styled.imgSize}>
									<img
										ref={refImage}
										src={sliderLastBlock} alt=""
										style={{width: phone ? '100%' : 'none'}}
									/>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
	);
};

export default SwiperCategory;